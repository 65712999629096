<template>
  <div class="container">


    <div class="syllabus-template mt-4">
      <h4 style="text-align: center">Шаблон силлабуса по дисциплине</h4>


      <AddSyllabusTemplateModal/>
      <UpdateSyllabusTemplateModal/>
      <DeleteSyllabusTemplateModal/>

      <div class="row mb-4">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#addSyllabusTemplate" @click="openModalAddSyllabus">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить шаблон силлабуса
          </button>
        </div>
      </div>

      <DataTable :value="syllabus_form.syllabusInfos" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <Column field="education_discipline_id" header="Дисциплина">
          <template #body="{data}">

            {{ data.educationDiscipline.name }}

          </template>
        </Column>
        <Column field="discipline_prerequisite" header="Пререквизиты">
          <template #body="{data}">
<!--            {{data.discipline_prerequisite}}-->
<!--            <br>-->
            <Button class="me-3" icon="pi pi-plus" @click="openPrerequisiteDialog(data.id)" />
            <Button class="p-button-warning me-3" icon="pi pi-info" @click="openInfoPrerequisiteDialog(data.id)" />
          </template>
        </Column>
        <Column field="discipline_postrequisite" header="Постреквизиты">
          <template #body="{data}">
<!--            {{data.discipline_postrequisite}}-->
<!--            <br>-->
            <Button class="me-3" icon="pi pi-plus" @click="openPostrequisiteDialog(data.id)" />
            <Button class="p-button-warning me-3" icon="pi pi-info" @click="openInfoPostrequisiteDialog(data.id)" />
          </template>
        </Column>
        <!--          <Column field="lecture_hour" header="Лекция"></Column>-->
        <!--          <Column field="seminar_hour" header="Семинар"></Column>-->
        <!--          <Column field="srsp_hour" header="СРСП"></Column>-->
        <!--          <Column field="srs_hour" header="СРС"></Column>-->
        <Column field="evaluation_option_id" header="Политика оценки ">
          <template #body="{data}">

            {{ data.evaluationOption.name }}

          </template>
        </Column>
        <Column header="Содержание дисциплины">

          <template #body="{data}">
            <router-link :to="'/syllabus-template-content?syllabus_id=' + data.id">
              Перейти
            </router-link>
          </template>

        </Column>

        <Column header="История силлабуса">
          <template #body="{data}">
            <router-link :to="'/syllabus-document-history?syllabus_id=' + data.id">
              Перейти
            </router-link>
          </template>
        </Column>


        <Column header="Редактировать">

          <template #body="{data}">
            <button type="button" class="btn btn-warning" data-bs-toggle="modal"
                    data-bs-target="#updateSyllabusTemplateModal"
                    @click="openUpdateSyllabusTemplateModal(data.id)">
              <i class="pi pi-pencil"></i>
            </button>
          </template>


        </Column>

        <Column header="Удалить">

          <template #body="{data}">
            <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                    data-bs-target="#deleteSyllabusTemplateModal"
                    @click="openDeleteSyllabusTemplateModal(data.id)">
              <i class="pi pi-trash"></i>
            </button>
          </template>


        </Column>


        <Column header="Печать">
          <template #body="{data}">
            <a
              :href="`https://back.uib.kz/syllabus_data/syllabus-print/get-syllabus?user_id=${data.user_id}&education_discipline_id=${data.education_discipline_id}&syllabus_id=${data.id}`"
              target="_blank" class="btn btn-primary m-1">
              <i class="pi pi-print"></i>
            </a>
          </template>
        </Column>


      </DataTable>


      <Dialog header="Добавление пререквизита" :visible="prerequisiteDisplay" style="width: 100%; max-width: 900px" :closable="false">


        <div class="col-md-12">
          <Button icon="pi pi-plus" label="Добавить дисциплину" @click="addRowPrerequisiteDiscipline" />
          <div class="form-group row mt-4" v-for="(discipline, index) in prerequisiteDisciplines" :key="index">
            <div class="job-remove-item__button d-flex flex-row-reverse">
              <i class="fa fa-times" @click="deleteRowPrerequisiteDiscipline(index)"></i>
            </div>
            <label class="col-md-3 col-form-label">Поиск дисциплины</label>
            <div class="col-md-9">
              <DropdownListEducationDiscipline
                  :options="options"
                  @search="onSearch"
                  @change="changeSelectedEducationDisciplinePrerequisite(index, $event)"
              />
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closePrerequisiteDialog" class="p-button-text"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitPrerequisite" :loading="btnSaveDisabled" />
        </template>
      </Dialog>


      <Dialog header="Добавление постреквизита" :visible="postrequisiteDisplay" style="width: 100%; max-width: 900px" :closable="false">


        <div class="col-md-12">
          <Button icon="pi pi-plus" label="Добавить дисциплину" @click="addRowPostrequisiteDiscipline" />
          <div class="form-group row mt-4" v-for="(discipline, index) in postrequisiteDisciplines" :key="index">
            <div class="job-remove-item__button d-flex flex-row-reverse">
              <i class="fa fa-times" @click="deleteRowPostrequisiteDiscipline(index)"></i>
            </div>
            <label class="col-md-3 col-form-label">Поиск дисциплины</label>
            <div class="col-md-9">
              <DropdownListEducationDiscipline
                  :options="options"
                  @search="onSearch"
                  @change="changeSelectedEducationDisciplinePostrequisite(index, $event)"
              />
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closePostrequisiteDialog" class="p-button-text"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitPostrequisite" :loading="btnSaveDisabled" />
        </template>
      </Dialog>


      <Dialog header="Пререквизиты" :visible="infoPrerequisiteDisplay" style="width: 100%; max-width: 900px" :closable="false">


        <div class="col-md-12">


          <div class="row mt-4">
            <div v-if="syllabus_form.syllabusInfos.find(i=>i.id == info_syllabus_id).syllabusRequisites.filter(i=>i.type == 1).length > 0">
              <ul>
                <li v-for="(item, index) in syllabus_form.syllabusInfos.find(i=>i.id == info_syllabus_id).syllabusRequisites.filter(i=>i.type == 1)"
                    :key="index">
                  {{item.educationDiscipline.name}} - {{item.educationDiscipline.language.native_name}}
                  <Button class="p-button-danger ms-3" icon="pi pi-trash" @click="deleteRequisiteDiscipline(item.id)" />
                </li>
              </ul>
            </div>
            <div v-else>
              <p>Дисциплины не добавлены</p>
            </div>
          </div>

        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closeInfoPrerequisiteDialog" class="p-button-text"/>
        </template>
      </Dialog>

      <Dialog header="Постреквизиты" :visible="infoPostrequisiteDisplay" style="width: 100%; max-width: 900px" :closable="false">


        <div class="col-md-12">

          <div class="row mt-4">
            <div v-if="syllabus_form.syllabusInfos.find(i=>i.id == info_syllabus_id).syllabusRequisites.filter(i=>i.type == 2).length > 0">
              <ul>
                <li v-for="(item, index) in syllabus_form.syllabusInfos.find(i=>i.id == info_syllabus_id).syllabusRequisites.filter(i=>i.type == 2)"
                    :key="index">
                  {{item.educationDiscipline.name}} - {{item.educationDiscipline.language.native_name}}
                  <Button class="p-button-danger ms-3" icon="pi pi-trash" @click="deleteRequisiteDiscipline(item.id)" />
                </li>
              </ul>
            </div>
            <div v-else>
              <p>Дисциплины не добавлены</p>
            </div>

          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closeInfoPostrequisiteDialog" class="p-button-text"/>
        </template>
      </Dialog>


    </div>


  </div>
</template>

<script>

  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import AddSyllabusTemplateModal from '@/components/syllabus/AddSyllabusTemplateModal.vue'
  import UpdateSyllabusTemplateModal from '@/components/syllabus/UpdateSyllabusTemplateModal.vue'
  import DeleteSyllabusTemplateModal from '@/components/syllabus/DeleteSyllabusTemplateModal.vue'
  import DropdownListEducationDiscipline from "@/components/common/DropdownListEducationDiscipline.vue";
  import {decryptCheckRole, getDecryptedRoles} from "@/utils/helpers/decryptedRoles.helpers";
  export default {
    name: "SyllabusTemplate",
    components: {
      AddSyllabusTemplateModal,
      UpdateSyllabusTemplateModal,
      DeleteSyllabusTemplateModal,
      DropdownListEducationDiscipline
    },
    data() {
      return {
        options: [],
        prerequisiteDisplay: false,
        postrequisiteDisplay: false,
        //selectedEducationDisciplineId: null,
        //selectedEducationDisciplineDepartmentId: null,
        prerequisiteDisciplines: [],
        postrequisiteDisciplines: [],
        selected_syllabus_id: null,
        btnSaveDisabled: false,
        infoPrerequisiteDisplay: false,
        infoPostrequisiteDisplay: false,
        info_syllabus_id: null,
      }
    },
    computed: {
      ...mapState('syllabus', ['syllabus_form']),
      ...mapState('teacher', ['teacher_form']),
    },

    methods: {
      ...mapActions('syllabus', ['GET_SYLLABUS_DATA', 'SEARCH_DISCIPLINE', 'POST_SYLLABUS_REQUISITES', 'DELETE_REQUISITE_DISCIPLINE']),
      ...mapMutations('syllabus', ['SET_UPDATE_SYLLABUS_TEMPLATE_ID', 'SET_DELETE_SYLLABUS_TEMPLATE_ID']),
      ...mapActions('teacher', ['GET_EXAM_TYPES']),

      async deleteRequisiteDiscipline(syllabus_requisites_id) {
        await this.DELETE_REQUISITE_DISCIPLINE(syllabus_requisites_id)
        await this.GET_SYLLABUS_DATA()
      },
      openInfoPrerequisiteDialog(syllabus_id) {
        this.infoPrerequisiteDisplay = true
        this.info_syllabus_id = syllabus_id
      },
      closeInfoPrerequisiteDialog(syllabus_id) {
        this.infoPrerequisiteDisplay = false
      },
      openInfoPostrequisiteDialog(syllabus_id) {
        this.infoPostrequisiteDisplay = true
        this.info_syllabus_id = syllabus_id
      },
      closeInfoPostrequisiteDialog(syllabus_id) {
        this.infoPostrequisiteDisplay = false
      },

      async openModalAddSyllabus(){
        if (this.teacher_form.examTypes.length === 0){
          await this.GET_EXAM_TYPES()
        }
      },

      openPrerequisiteDialog(syllabus_id) {
        this.prerequisiteDisplay = true

        this.selected_syllabus_id = syllabus_id
        console.log(this.selected_syllabus_id, 'selected_syllabus_id')
      },
      closePrerequisiteDialog() {
        this.prerequisiteDisplay = false
      },

      openPostrequisiteDialog(syllabus_id) {
        this.postrequisiteDisplay = true

        this.selected_syllabus_id = syllabus_id
        console.log(this.selected_syllabus_id, 'selected_syllabus_id')
      },
      closePostrequisiteDialog() {
        this.postrequisiteDisplay = false
      },
      changeSelectedEducationDisciplinePrerequisite(index, e) {
        let selectedEducationDisciplineId = e.id
        //this.selectedEducationDisciplineDepartmentId = e.department_id

        console.log(selectedEducationDisciplineId, 'selectedEducationDisciplineId')
        console.log(index, 'index')


        this.prerequisiteDisciplines[index].discipline_id = selectedEducationDisciplineId
        //console.log(this.selectedEducationDisciplineDepartmentId, 'selectedEducationDisciplineDepartmentId')

        // if (this.prerequisiteDisciplines.find((i, index) => index == index).discipline_id == null) {
        //   this.prerequisiteDisciplines.find((i, index) => index == index).discipline_id = this.selectedEducationDisciplineId
        // }
        console.log(this.prerequisiteDisciplines, 'prerequisiteDisciplines')


      },
      changeSelectedEducationDisciplinePostrequisite(index, e) {
        let selectedEducationDisciplineId = e.id
        this.postrequisiteDisciplines[index].discipline_id = selectedEducationDisciplineId
        console.log(this.postrequisiteDisciplines, 'postrequisiteDisciplines')

      },
      async submitPrerequisite() {
        this.btnSaveDisabled = true
        this.prerequisiteDisplay = false
        //let form = []
        // let disciplines = this.prerequisiteDisciplines.map(function(item) {
        //   return item['discipline_id'];
        // });

        let form = this.prerequisiteDisciplines.map(i => ({
          syllabus_id: this.selected_syllabus_id,
          discipline_id: i.discipline_id,
          type: 1

        }))
        // form.push({
        //   syllabus_id: this.prerequisite_syllabus_id,
        //   prerequisite_disciplines: disciplines.toString()
        // })
        console.log(form, 'submit prerequisite form')


        let postData = await this.POST_SYLLABUS_REQUISITES(form)
        if (postData) {

          await this.GET_SYLLABUS_DATA()
          this.$message({text: 'Пререквизиты добавлены'})
          //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
        }
        this.prerequisiteDisciplines = []
        this.selected_syllabus_id = null

      },

      async submitPostrequisite() {
        this.btnSaveDisabled = true
        this.postrequisiteDisplay = false

        let form = this.postrequisiteDisciplines.map(i => ({
          syllabus_id: this.selected_syllabus_id,
          discipline_id: i.discipline_id,
          type: 2

        }))
        console.log(form, 'submit postrequisite form')

        let postData = await this.POST_SYLLABUS_REQUISITES(form)
        if (postData) {
          await this.GET_SYLLABUS_DATA()
          this.$message({text: 'Постреквизиты добавлены'})
          //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
        }
        this.postrequisiteDisciplines = []
        this.selected_syllabus_id = null

      },
      onSearch(search) {
        if (search.length > 3) {
          this.SEARCH_DISCIPLINE(search).then(json => (this.options = json));
        }
      },
      addRowPrerequisiteDiscipline() {
        this.prerequisiteDisciplines.push({
          discipline_id: null,
        });
        console.log(this.prerequisiteDisciplines, 'prerequisiteDisciplines')
      },
      deleteRowPrerequisiteDiscipline(index) {
        this.prerequisiteDisciplines.splice(index, 1);
        console.log(this.prerequisiteDisciplines, 'prerequisiteDisciplines')
      },
      addRowPostrequisiteDiscipline() {
        this.postrequisiteDisciplines.push({
          discipline_id: null,
        });
        console.log(this.postrequisiteDisciplines, 'postrequisiteDisciplines')
      },
      deleteRowPostrequisiteDiscipline(index) {
        this.postrequisiteDisciplines.splice(index, 1);
        console.log(this.postrequisiteDisciplines, 'postrequisiteDisciplines')
      },
      async openUpdateSyllabusTemplateModal(updateSyllabusTemplateId) {
        console.log(updateSyllabusTemplateId, 'updateSyllabusTemplateId')
        this.SET_UPDATE_SYLLABUS_TEMPLATE_ID(updateSyllabusTemplateId)
        await this.openModalAddSyllabus()
      },
      openDeleteSyllabusTemplateModal(deleteSyllabusTemplateId) {
        this.SET_DELETE_SYLLABUS_TEMPLATE_ID(deleteSyllabusTemplateId)
      }
    },
    mounted() {
      this.GET_SYLLABUS_DATA();
    }

  }
</script>

<style scoped>

</style>
